.neo-sip-menu-item {
  list-style: none;
  z-index: 11;
  margin-left: -1rem;
}

.neo-menu-link-wrapper {
  position: relative;
  z-index: 11;
  display: block;
  width: 100%;
}

.neo-menu-link {
  z-index: 11;
  display: flex;
  align-items: center;
  color: var(--neo-text-color-light);
  gap: 1rem;
  padding-left: 0.5rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--neo-hover-bg-color-light);

    html.dark & {
      background-color: var(--neo-hover-bg-color-dark);
    }
  }

  &.active {
    background-color: var(--neo-active-bg-color-light);
    color: var(--neo-active-text-color-light);

    html.dark & {
      background-color: var(--neo-active-bg-color-dark);
      color: var(--neo-active-text-color-dark);
    }
  }
}

.neo-menu-icon {
  z-index: 11;
  width: 16px;
  height: 16px;

  html.dark & {
    filter: invert(1);
  }
}

.neo-menu-content {
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.neo-menu-title {
  font-size: 1rem;
  z-index: 11;

  html.dark & {
    color: var(--neo-text-color-dark);
  }
}

.neo-menu-badge {
  padding: 0.125rem 0.5rem;
  color: white; /* ausnahmsweise ohne var */
  background-color: var(--neo-bg-red);
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;

  html.dark & {
    color: white; /* ausnahmsweise ohne var */
    background-color: var(--neo-bg-red);
  }
}

.neo-tooltip {
  position: relative;
}

/* color und background-color sind bewusst bei light/dark vertauscht */
.neo-tooltip-text {
  display: none;
  width: 169px;
  padding: 0.25rem 0.5rem;
  color: var(--neo-text-color-dark);
  transform: translate(40px, -50%);
  background-color: var(--neo-bg-color-dark);
  border-radius: 4px;
  font-size: 0.7rem;
  white-space: nowrap;

  html.dark & {
    color: var(--neo-text-color-light);
    background-color: var(--neo-bg-color-light);
  }
}

.neo-tooltip:hover .neo-tooltip-text {
  display: block;
}

.neo-menu-accordion {
  z-index: 11;
  margin-left: -0.85rem;
}

.neo-accordion-toggle {
  z-index: 11;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--neo-text-color-light);
  gap: 1rem;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  text-align: left;
  border: none;

  &:hover {
    background-color: var(--neo-hover-bg-color-light);

    html.dark & {
      background-color: var(--neo-hover-bg-color-dark);
      color: var(--neo-text-color-dark);
    }
  }

  &.active {
    color: var(--neo-active-text-color-light);

    html.dark & {
      color: var(--neo-active-text-color-dark);
    }
  }
}

.neo-accordion-icon {
  z-index: 11;
  width: 12px;
  height: 12px;
  background: url('../../../assets/svgs/down-arrow.svg') no-repeat center center;
  background-size: contain;
  transition: transform 0.3s ease;

  &:hover {
    filter: invert(0.5);
  }

  html.dark & {
    filter: invert(1);
  }

  &.expanded {
    transform: rotate(180deg);
  }
}

.neo-accordion-content {
  display: none;
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0;

  &.open {
    display: block;
    max-height: 100vh;
    margin-left: -1rem;
    margin-top: 0.5rem;
  }
}
