:root {
  --neo-bg-color-light: #fff;
  --neo-bg-color-dark: #1e1e1e;
  --neo-element-bg-color-light: #f9fafb;
  --neo-element-bg-color-dark: #252526;
  --neo-border-color-light: #e1e1e1;
  --neo-border-color-dark: #3c3c3c;
  --neo-accent-color: #007acc;
  --neo-text-color-light: #3f3f46;
  --neo-text-color-dark: #d4d4d4;
  --neo-hover-bg-color-light: #f9fafb;
  --neo-hover-bg-color-dark: #3f3f46;
  --neo-active-bg-color-light: #e5e7eb;
  --neo-active-bg-color-dark: #374151;
  --neo-active-text-color-light: #1f2937;
  --neo-active-text-color-dark: #f9fafb;
  --neo-success-color: #10b981;
  --neo-warn-color: #f97316;
  --neo-error-color: #e3342f;
  --neo-bg-red: #f20909;
}

html,
body,
#app {
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.neo-body {
  overflow-y: hidden;

  html.dark & {
    background-color: var(--neo-bg-color-dark);
  }
}

.scrollbar-hide {
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.neo-screen-reader {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.app-toast-spacing div+div {
  margin-top: 8px;
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 300;
  src: url('./assets/fonts/RobotoCondensed-Light.ttf');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 400;
  src: url('./assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Condensed';
  font-weight: 700;
  src: url('./assets/fonts/RobotoCondensed-Bold.ttf');
}

* {
  font-family: 'Roboto Condensed', sans-serif;
  scrollbar-width: thin;
}

/* sip */
piral-portal>* {
  /* Gilt für jedes Element, das ein Kind von piral-portal ist */
  height: 100%;

  /* TabPanels padding normalize */
  .p-tabpanels {
    padding: 16px 0 0 0;
  }

  /* TabPanels padding */
  .tab-px {
    padding-left: 16px;
    padding-right: 16px;
  }

  /* Auf jeder Form-Seite */
  .sip-form-container {
    display: flex;
    flex-wrap: wrap;
    gap: 64px;

    /* Ein Block in einer Form Seite */
    .sip-grid {
      display: flex;
      flex-direction: column;
      width: 800px;

      h1 {
        width: 100%;
        border-bottom: 1px solid black;
        margin-bottom: 24px;
        font-weight: bold;
      }

      .sip-label-wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 200px 1fr;
        margin: 4px 0;
        text-align: left;
      }

      label {
        display: grid;
        align-items: center;
        grid-template-columns: 200px 1fr;
        margin: 4px 0;
        text-align: left;
      }

      @media (width <=720px) {
        .sip-label-wrapper {
          grid-template-columns: 1fr;
        }

        label {
          grid-template-columns: 1fr;
        }
      }

      /* Bei mehr als 2 Inputs */
      .sip-input-group {
        display: flex;

        .large {
          width: 65%;
        }

        .small {
          width: 35%;
        }

        .half {
          width: 50%;
        }

        .xlarge {
          width: 90%;
        }

        .xsmall {
          width: 10%;
        }

        *:has(+ *) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        *+* {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left-width: 0;
        }

        *:focus {
          z-index: 999;
          border-left-width: 1px;
        }
      }

      /* Bei Input + Button / Dropdown etc. */
      .sip-form-group {
        display: flex;
        gap: 8px;

        .large {
          width: 65%;
        }

        .small {
          width: 35%;
        }

        .half {
          width: 50%;
        }

        .xlarge {
          width: 90%;
        }

        .xsmall {
          width: 10%;
        }

        /* position: relative;
        > :first-child:has(+ :last-child) {
          width: calc(100% - 60px);
        }
        > :last-child {
          position: absolute;
          top: 0;
          right: 0;
        } */
      }
    }
  }
}
