.neo-toast-icon {
  width: 1rem;
  height: 1rem;
  margin-top: 0.125rem;

  &.green {
    color: var(--neo-success-color);
  }

  &.orange {
    color: var(--neo-warn-color);
  }

  &.red {
    color: var(--neo-error-color);
  }
}

.neo-close-icon {
  width: 0.875rem;
  height: 0.875rem;
}

.neo-toast {
  z-index: 1337;
  width: 24rem;
  border: 1px solid var(--neo-border-color-light);
  background-color: var(--neo-bg-color-light);
  border-radius: 4px;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    0 4px 6px -2px rgb(0 0 0 / 5%);

  html.dark & {
    border: 1px solid var(--neo-border-color-dark);
    background-color: var(--neo-bg-color-dark);
  }
}

.neo-toast-container {
  display: flex;
  padding: 1rem;
}

.neo-toast-type {
  flex-shrink: 0;
}

.neo-toast-content {
  margin-left: 1rem;

  h3 {
    color: var(--neo-text-color-light);

    html.dark & {
      color: var(--neo-text-color-dark);
    }
  }

  h3 + div {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #4b5563; /* ohne var */

    html.dark & {
      color: #9ca3af; /* ohne var */
    }
  }
}

.neo-toast-close {
  margin-left: auto;

  .neo-toast-close-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: transparent;
    color: var(--neo-text-color-light);
    border: none;
    flex-shrink: 0;
    border-radius: 4px;
    transition: color 0.2s;
    cursor: pointer;

    &:hover {
      color: var(--neo-hover-color-light);
    }

    &:focus {
      outline: none;
    }

    html.dark & {
      color: var(--neo-text-color-dark);

      &:hover {
        color: var(--neo-hover-color-dark);
      }
    }
  }
}
