.neo-loading {
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
}

.neo-loading-container {
  animation: spin 1s linear infinite;
  width: 2.5rem;
  height: 2.5rem;
  border-width: 3px;
  border-style: solid;
  border-color: currentcolor;
  border-top-color: transparent;
  color: var(--neo-accent-color);
  border-radius: 9999px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
