.neo-snowfall-dropdown {
  position: relative;
  display: inline-block;
}

.neo-snowfall-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  background-color: var(--neo-bg-color-light);
  cursor: pointer;
  border: none;

  &:hover {
    background-color: var(--neo-hover-bg-color-light);
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
  }

  html.dark & {
    background-color: var(--neo-bg-color-dark);

    &:hover {
      background-color: var(--neo-hover-bg-color-dark);
    }
  }
}

.neo-snowfall-dropdown-content {
  position: absolute;
  top: 55px;
  right: 10px;
  background-color: white;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  border-radius: 4px;
  z-index: 1000;
  min-width: 8rem;
  border: 1px solid var(--neo-border-color-light);

  html.dark & {
    background-color: var(--neo-element-bg-color-dark);
    border: 1px solid var(--neo-border-color-dark);
  }
}

.neo-snowfall-dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.75rem;
  color: var(--neo-text-color-light);
  gap: 0.875rem;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  font-size: 0.875rem;
  border: none;

  &:hover {
    background-color: var(--neo-hover-bg-color-light);
  }

  html.dark & {
    color: var(--neo-text-color-dark);
    background-color: var(--neo-element-bg-color-dark);

    &:hover {
      background-color: var(--neo-hover-bg-color-dark);
    }
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
}
