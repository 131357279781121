.neo-http {
  font-size: 1.5rem;
  font-weight: 700;
}

.neo-error {
  color: var(--neo-error-color);
  font-size: 1rem;
  margin-top: 0.5rem;
  max-width: 500px;
}

.neo-login {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}

.neo-center {
  align-self: center;
}

.neo-underline {
  text-decoration: underline;
}
