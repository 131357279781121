.neo-profile-button {
  position: relative;
  display: inline-flex;
  border-radius: 50%;
}

.neo-profile-dropdown-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  border-radius: 50%;
  background-color: #535b67; /* Salamander Grey */
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.neo-profile-initials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

.neo-profile-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  min-width: 15rem;
  background-color: var(--neo-bg-color-light);
  border-radius: 0.5rem;
  border: 1px solid var(--neo-border-color-light);
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  opacity: 0;
  transition:
    opacity 0.3s,
    margin 0.3s;

  &.open {
    display: block;
    opacity: 1;
  }

  html.dark & {
    background-color: var(--neo-bg-color-dark);
    border: 1px solid var(--neo-border-color-dark);
  }
}

.neo-profile-menu-header {
  padding: 0.75rem 1.25rem;
  background-color: var(--neo-element-bg-color-light);
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 1px solid var(--neo-border-color-light);

  p {
    margin: 0;
  }

  .version {
    text-align: right;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--neo-text-color-light);
  }

  .details {
    font-size: 0.875rem;
    color: var(--neo-text-color-light);
  }

  .name {
    font-weight: 600;
    color: var(--neo-text-color-light);
  }

  html.dark & {
    background-color: var(--neo-element-bg-color-dark);
    color: white;
    border-bottom: 1px solid var(--neo-border-color-dark);

    p,
    .version,
    .details,
    .name {
      color: var(--neo-text-color-dark);
    }
  }
}

.neo-profile-menu-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.75rem;
  gap: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  background-color: var(--neo-bg-color-light);
  border: none;

  &:hover {
    background-color: var(--neo-hover-bg-color-light);
  }

  html.dark & {
    background-color: var(--neo-bg-color-dark);

    &:hover {
      background-color: var(--neo-hover-bg-color-dark);
    }
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}
