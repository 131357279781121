.neo-app-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 250px;
  height: 100vh;
  background-color: var(--neo-bg-color-light);
  border-right: 1px solid var(--neo-border-color-light);
  transition: transform 0.3s ease-in-out;

  &.collapsed {
    transform: translateX(-100%);
  }

  &.expanded {
    transform: translateX(0);
  }

  html.dark & {
    background-color: var(--neo-bg-color-dark);
    border-right-color: var(--neo-border-color-dark);
  }
}

.neo-lurchi {
  position: absolute;
  inset: 0;
  z-index: 10;
  background: url('../../assets/svgs/lurchi_light.svg') no-repeat left bottom;

  html.dark & {
    background: url('../../assets/svgs/lurchi_dark.svg') no-repeat left bottom;
  }
}

.neo-sidebar-content {
  height: 85vh;
  margin-top: 4.5rem;
  overflow-y: auto;
  padding-right: 1rem;

  ul {
    list-style: none;
  }

  @media (height <=1200px) {
    height: 80vh;
  }

  @media (height <= 1000px) {
    height: 75vh;
  }
}

.neo-sidebar-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 1.5rem;

  svg {
    fill: var(--neo-text-color-light);
    width: 1.25rem;
    height: 1.25rem;

    html.dark & {
      fill: var(--neo-text-color-dark);
    }
  }

  a {
    color: var(--neo-text-color-light);
    text-decoration: underline;

    &:hover {
      color: var(--neo-hover-color-light);
    }

    html.dark & {
      color: var(--neo-text-color-dark);

      &:hover {
        color: var(--neo-hover-color-dark);
      }
    }
  }
}

.neo-sidebar-imprint {
  color: var(--neo-text-color-light);

  &:hover {
    color: var(--neo-hover-color-light);
  }

  html.dark & {
    color: var(--neo-text-color-dark);

    &:hover {
      color: var(--neo-hover-color-dark);
    }
  }
}

.neo-nav-bar {
  position: fixed;
  top: 76px;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 76px;
  margin: 0;
  background-color: var(--neo-bg-color-light);
  border-top: 1px solid var(--neo-border-color-light);
  border-bottom: 1px solid var(--neo-border-color-light);


  .neo-nav-bar-breadcrumbs {
    display: flex;
    align-items: center;
    height: 76px;
    margin-left: 96px;

    svg {
      margin-top: 0.5rem;
    }
  }

  html.dark & {
    background-color: var(--neo-bg-color-dark);
    border-color: var(--neo-border-color-dark);
  }
}

.neo-sidebar-button {
  position: fixed;
  top: 102px;
  left: 25px;
  z-index: 50;
  background: none;
  cursor: pointer;
  border: none;
}
