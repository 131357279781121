.neo-login-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background: transparent;
  color: var(--neo-text-color-light);
  border: none;
  gap: 0.875rem;
  border-radius: 4px;
  font-size: 0.875rem;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--neo-hover-bg-color-light);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--neo-accent-color);
  }
}

.neo-login-button-icon {
  width: 1.25rem;
  height: 1.25rem;
}
