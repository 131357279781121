.neo-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 48;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  background-color: var(--neo-bg-color-light);
  border-bottom: 1px solid var(--neo-border-color-light);
  font-size: 0.875rem;
  padding: 16px 0;

  @media (width >= 640px) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 16px 0;
  }

  html.dark & {
    background-color: var(--neo-bg-color-dark);
    border-color: var(--neo-border-color-dark);
  }
}

.neo-header-nav {
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
  flex-basis: 100%;
  padding: 0 24px;
}

.neo-header-logo {
  width: 250px;
}

.neo-header-breadcrumbs {
  position: absolute;
  left: 250px;
  display: none;
  max-width: 50%;

  @media (width > 1024px) {
    display: block;
  }

  svg {
    margin-top: 0.25rem;
  }
}

.neo-header-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 0.5rem;
}
