.neo-test-button {
  position: relative;
  display: inline-flex;
}

.neo-dropdown-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  color: var(--neo-text-color-light);
  border-radius: 50%;
  background-color: transparent;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: var(--neo-hover-bg-color-light);
  }

  html.dark & {
    &:hover {
      background-color: var(--neo-hover-bg-color-dark);
    }

    p {
      color: var(--neo-text-color-dark);
    }
  }
}

.neo-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  display: none;
  padding: 0.5rem;
  min-width: 240px;
  background-color: var(--neo-bg-color-light);
  border-radius: 4px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  border: 1px solid var(--neo-border-color-light);
  opacity: 0;
  transition:
    opacity 0.3s,
    margin 0.3s;

  &.open {
    display: block;
    opacity: 1;
  }

  html.dark & {
    background-color: var(--neo-bg-color-dark);
    border-color: var(--neo-border-color-dark);
  }
}

.neo-dropdown-item {
  margin-top: 0.5rem;

  &:first-child {
    margin-top: 0;
  }

  button {
    width: 100%;
    padding: 0.5rem 1rem;
    color: var(--neo-text-color-light);
    border-radius: 4px;
    font-size: 0.875rem;
    background-color: transparent;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border: none;

    &:hover {
      background-color: var(--neo-hover-bg-color-light);
    }

    html.dark & {
      background-color: var(--neo-element-bg-color-dark);
      color: var(--neo-text-color-dark);

      &:hover {
        background-color: var(--neo-hover-bg-color-dark);
      }
    }
  }
}
